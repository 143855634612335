.container {
	padding: 20px;
	min-height: 150px;
	max-height: 300px;
	min-width: 360px;
	overflow-y: auto;
}

table {
	width: 100%;
	border-collapse: collapse;
}

th,
td {
	border: 1px solid black;
	padding: 8px;
	text-align: center;
}

.expand_payout_image {
	width: 80px;
	height: auto;
}