.container {
	min-width: 360px;
	display: flex;
	flex-direction: column;
	height: 100px;
	padding: 20px;
	font-size: 9px;
}

.info {
	display: flex;
	flex-direction: row;
	height: 30px;
}