.container {
	max-width: 760px;
	min-width: 360px;
	height: 130px;
	padding: 20px;
	display: grid;
	grid-template-columns: 2fr repeat(5, 1fr);
	grid-template-rows: auto auto;
}

.grid_item {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	border: 1px solid black;
	padding: 8px;
}

.header {
	@extend .grid_item;
}

.large {
	@extend .grid_item;
	grid-column: 1;
	grid-row: 1 / span 2;
}

.image {
	@extend .grid_item;
	grid-column: 2 / span 2;
	grid-row: 1 / span 2;
}

.scatter_image {
	width: 100px;
	height: auto;
}