.container {
	max-width: 760px;
	min-width: 360px;
	height: 400px;
	min-height: 200px;
	max-height: 400px;
	padding: 20px;
	margin-bottom: 20px;
	overflow-y: auto;

	table {
		width: 100%;
		border-collapse: collapse;

		th {
			color: black;
			min-width: 100px;
		}

		th,
		td {
			border: 1px solid #000;
			text-align: center;
			padding: 8px;
		}
	}
}