.container {
	display: flex;
	flex-direction: row;
	padding-left: 20px;
	padding-right: 20px;

	.game_result_container{
		width: 400px;
	}
}

.result {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #000;
}

.result_image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.bet_detail {
	width: 400px;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-auto-rows: 72px;
	padding: 10px;
	gap: 10px;

	.info_item {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		text-align: center;

		.label {
			font-weight: bold;
		}

		.value {
			font-weight: normal;
		}
	}

	.btn_show_free_game{
		height: 70%;
    	cursor: pointer;
	}
}

@media (max-width: 799px) {
	.container {
		display: block;

		.game_result_container{
			min-width: 360px;
			width: auto;
		}
	}

	.bet_detail {
		padding: 10px 0;
		min-width: 360px;
		width: auto;
	}
}